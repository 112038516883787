<template>
	<div class="container-fluid relative animatedParent animateOnce my-3">
		<div class="tab-pane animated fadeInUpShort show active go">
			<div class="row my-3">
				<div class="col-md-12">
					<div class="card r-0 shadow">
						<div class="card-header">
							
							
							<div class="row my-3">
								<div class="col-md-6">
									<!-- 搜索 start -->
									<div class="input-group w-100 m-3 form-inline"><div class="input-group-btn">
										<input v-model="query" class="form-control p-2" placeholder="搜索" type="text">
										
										<button @click="search" type="button" class="btn btn-secondary ml-2"><i class="icon-search"></i>
										</button>
										</div>
									</div>
									<!-- 搜索 end -->
								</div>
								<div class="col-md-6 ">
									<div class="float-right m-3">
										<!-- <button  @click="add" type="button" class="btn btn-secondary">额度充值</button> -->
									</div>	
								</div>	
							</div>	
						</div>
	
						<div class="table-responsive">
							<div>
								<table class="table table-striped table-hover r-0 mb-0">
									<thead>
										<tr class="no-b">
											<th style="width: 60px">
												<!-- <div class="custom-control custom-checkbox">
													<input type="checkbox" id="checkedAll" class="custom-control-input"><label class="custom-control-label" for="checkedAll"></label>
												</div> -->
												 <div class="d-none d-lg-block">序号</div>
											</th>
											<th>
												<div class="d-none d-lg-block">企业名称</div>
											</th>
											<th>
												<div class="d-none d-lg-block">额度类型</div>
											</th>
											
											<th>
												<div class="d-none d-lg-block">已用额度(元)</div>
											</th>
											
											<th>
												<div class="d-none d-lg-block">可用额度(元)</div>
											</th>
											
											<!-- <th></th> -->
										</tr>
									</thead>
	
									<tbody>
										<tr v-for="(item,index) in quotalist" v-bind:key="index" v-show="item.is_default != 1" 
										:class="{'blue lighten-5':selectedId == item.id}">
											<td>
												<!-- <div class="custom-control custom-checkbox">
													<input type="checkbox" class="custom-control-input checkSingle" id="user_id_32" required=""><label class="custom-control-label" for="user_id_1"></label>
												</div> -->
												<div class="d-none d-lg-block">{{index+1}}</div>
											</td>
											
											<td>
												<div class="d-none d-lg-block">{{deptmap[item.dept_id].department_name}}</div>
											</td>
											
											<td>
												<div class="d-none d-lg-block">{{expenseTypeMap[item.expense_type]}}</div>
											</td>
											
											<td>
												<div class="d-none d-lg-block">{{item.total_used}}</div>
											</td>
											
											<td>
												<div class="d-none d-lg-block">{{item.total_available}}</div>
											</td>
											
											<!-- <td>
												<button @click.stop="edit(index)" type="button" class="btn btn-outline-primary btn-xs"> 编辑 </button>
																								 
											</td> -->
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
	
			<!-- <div class="row">
				<div class="col-sm-12 col-md-5">
					<div class="dataTables_info" id="example2_info" role="status" aria-live="polite">
						显示  {{show_from}} ~ {{show_to}} 条 / 共 {{count}} 条
					</div>
				</div>
				<div class="col-sm-12 col-md-7">
					
					<v-pagination :total="total" :current-page='current' @pagechange="pagechange"></v-pagination>
					
				</div>
			</div> -->
	
		</div>
		
		<!--Add New Message Fab Button-->
		<!-- <div @click="add" class="btn-fab btn-fab-md fab-right fab-right-bottom-fixed shadow btn btn-secondary"><i class="icon-add"></i></div> -->
		
		<!-- modal start -->
		<div class="modal fade" id="modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		  <div class="modal-dialog">
		    <div class="modal-content">
		      <div class="modal-header">
		        <h5 class="modal-title" id="exampleModalLabel">额度充值</h5>
		        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
		          <span aria-hidden="true">&times;</span>
		        </button>
		      </div>
		      <div class="modal-body">
		        <div>
		          
				  <div class="form-group">
				  	<label for="" class="s-12 font-weight-bold">归属企业</label>
				  	<select v-model="quota.dept_id" class="form-control">
				  		<option value="">选择企业</option>
				  		<option v-bind:key="index"
				  			:value="item.id" v-for="(item,index) in quotalist">
				  			{{item.department_name}}</option>
				  	</select>
				  	<div class="s-12">
				  	</div>	
				  </div>
				  
				  <div class="form-group">
				  	<label for="" class="s-12 font-weight-bold">额度类型</label>
				  	<select v-model="quota.expense_type" class="form-control">
				  		<option v-bind:key="index" :value="item.value"
				  			v-for="(item,index) in expensetypelist">{{item.name}}</option>
				  	</select>
				  	<div class="s-12">
				  	</div>	
				  </div>
				  
				  <div class="form-group">
				  	<label for="" class="s-12 font-weight-bold">充值金额(元)</label>
				  	<input v-model="quota.pay" type="number" class="form-control"
				  		placeholder="充值金额">
				  	<div class="s-12">
				  	</div>	
				  </div>
				  
				  <div class="form-group">
				  	<label for="" class="s-12 font-weight-bold">备注</label>
				  	<input v-model="quota.remark" type="text" class="form-control"
				  		placeholder="充值备注">
				  	<div class="s-12">
				  	</div>	
				  </div>
				  
		        </div>
		      </div>
		      <div class="modal-footer">
		        <button type="button" class="btn btn-secondary" data-dismiss="modal">取消</button>
		        <button v-if="selectedIndex != -1" @click="removeReady(selectedIndex)" type="button" class="btn btn-danger"> 删除 </button>
				 <button @click="save" :disabled="disabled" type="button" class="btn btn-primary"> 保存 </button>
		      </div>
		    </div>
		  </div>
		</div>
		<!-- modal end -->
		
	</div>		
</template>

<script>
module.exports = {
    data: function() {
        return {
			title: '企业',
			
			token: '',
			user_role: '',
			
			ent_id: 0,
			dept_id: 0,
			
			// ent_name: '',
			// dept_name: '',
			
			// user_name: '',
			
			quotalist: [],
			query: '',
			
			department: {},
			selectedIndex: -1,
			
			selectedId: 0,
			
			disabled: false,
			
			quota: {},
			expensetypelist: [
				{name: '选择费用类型', value: ''},
				{name: '餐饮', value: 'MEAL'},
				{name: '通用', value: 'DEFAULT'},
			],
			
			////
			departmentlist: [],
			// dept_id: '',
			deptmap: {}, //key为 dept id, value为 dept
			
			expenseTypeMap: {
				'MEAL':'餐饮',
				'DEFAULT': '通用'
			},
        }
    },
    props: {
      
    },
	
	// components: {
	// 	// 将组建加入组建库
	// 	// 'v-pagination': 'url:./component/my-page.vue',
	// 	'v-sibebar': 'url:/component/aside.vue',
	// 	'v-navbar': 'url:/component/navbar.vue',
	// 	'v-right-sidebar': 'url:/component/right-sidebar.vue'
	// },
	
	created: function() {
		console.log('--- created --- ')
		let self = this;
		
		//1.检查登录
		let token = localStorage.getItem('TOKEN');
		// if (!token) {
		// 	return;
		// }
		self.token = token;
		
		//2.取得店铺信息
		let lsLoginData = JSON.parse(localStorage.getItem('LOGINDATA'));
		console.log(lsLoginData.dept_name)
		self.ent_id = lsLoginData.ent_id;
		console.info('--- this.$store.state ->',this.$store.state)
		self.dept_id = lsLoginData.dept_id;
		self.user_role = lsLoginData.user_role;
		
		// self.ent_name = lsLoginData.ent_name;
		// self.dept_name = lsLoginData.dept_name;
		
		// self.user_name = lsLoginData.user_account;
		
		// self.setting = JSON.parse(localStorage.getItem('SETTING'));
		
		// self.outletlist = JSON.parse(localStorage.getItem('OUTLETLIST'));
		// console.log('--- outlet list ->', self.outletlist)
	},
	mounted: function() {
		console.log('--- mounted --- ')
		let self = this;
		
		//1.检查登录
		// let token = localStorage.getItem('TOKEN');
		// if (!self.token) {
		// 	console.log('--- mounted jump  --- ')
		// 	location.href = 'index.html';
		// 	return;
		// }
		
		self.init(() => {
			self.initData();
		})
	},
    methods:{
		init(callback) {
			let self = this;
		
			//
			this.axios.get(this.GLOBAL.baseURI + 'getDepartmentList', {
					params: {
						token: self.token,
						dept_id: self.dept_id,
						// query: self.query,
						// current_page: self.current_page,
						// page_size: self.page_size,
					}
				})
				.then(function(response) {
					console.log(response);
					return response.data;
				})
				.catch(function(error) {
					console.log(error);
				})
				.then(function(data) {
					// always executed
					console.log(data.data);
					if (data.code == 200) {
						self.departmentlist = data.data.departments;
						
						for (let i = 0; i < self.departmentlist.length; i++) {
							let dept = self.departmentlist[i];
							self.deptmap[dept.id] = dept;
						}
						console.log('--- dept map ->', self.deptmap);
						
						self.current_date = data.data.current_date;
						
						if (typeof callback == 'function') {
						    callback()
						}
					} 
					else if (data.code == 101) {
						//登录超时
						// location.href = "index.html";
						self.logoff();
					} 
					else {
						alert(data.message)
					}
				});
			//END
		
		},
		
    	initData() {
    		let self = this;
    	
    		//
    		this.axios.get(this.GLOBAL.baseURI + 'getQuotaList', {
    				params: {
    					token: self.token,
    					dept_id: self.dept_id,
    				}
    			})
    			.then(function(response) {
    				console.log(response);
    				return response.data;
    			})
    			.catch(function(error) {
    				console.log(error);
    			})
    			.then(function(data) {
    				// always executed
    				console.log(data.data);
    				if (data.code == 200) {
						
						self.quotalist = [];
						
    					let list = data.data.settings;
						for(let i=0; i<list.length; i++){
							let qs = list[i];
							let values = JSON.parse(qs.setting_value);
							
							for(let j=0; j<values.length; j++){
								let value = values[j];
								
								//组装quota
								let quota = {
									dept_id: qs.dept_id,
									expense_type: value.expense_type,
									total_pay: value.total_pay,
									total_used: value.total_used,
									total_available: value.total_available,
								}
								
								self.quotalist.push(quota);
							}
						}
						
    				} 
					else if (data.code == 101) {
    					//登录超时
    					// location.href = "index.html";
						self.logoff();
    				} 
					else {
    					alert(data.message)
    				}
    			});
    		//END
    	
    	},
    	
    	search() {
    	
    		let self = this;
    		self.quotalist = __LIST.filter(item => item.department_name.indexOf(self.query) != -1);
    	},
    	
    	add() {
    		var self = this;
    	
    		// location.href = 'editcategory.html';	
    	
    		self.selectedIndex = -1;
    		self.quota = {
				id: 0,
				dept_id: '',
				expense_type: '',
				pay: '',
				reamrk: '',
			};
			
    		$('#modal').modal('show');
    	},
    	
    	edit(index) {
    		console.log(index);
    		let self = this;
    	
    		// let cg = self.quotalist[index];
    	
    		self.selectedIndex = index;
    		self.department = Object.assign({}, self.quotalist[index]);
			self.selectedId = self.department.id;
    		console.log(self.selectedIndex, self.department);
    	
    		self.oldName = self.department.department_name;
    	
    		$('#modal').modal('show');
    	},
    	
    	save() {
    		let self = this;
    		console.log(self.quota);
			
			if(self.disabled) return;
    	
    		console.log('--- self.quota ->', self.quota)
    		
    		if (!self.quota.dept_id) {
    			self.$toasted.error('请选择归属企业', {
    				position: 'top-center',
    			}).goAway(2000)
    			return;
    		}
    		
    		if (!self.quota.expense_type) {
    			self.$toasted.error('请选择额度类型', {
    				position: 'top-center',
    			}).goAway(2000)
    			return;
    		}
    		
    		if (!self.quota.pay) {
    			self.$toasted.error('请输入充值金额', {
    				position: 'top-center',
    			}).goAway(2000)
    			return;
    		}
    		
    		self.disabled = true;
    	
    		// 组装数据
    		let postData = {
    			token: self.token,
    			// enterprise_id: self.enterprise_id,//后端TOKEN中取得
    			dept_id: self.quota.dept_id,//添加时为0
    			expense_type: self.quota.expense_type,
				pay: self.quota.pay,
				remark: self.quota.remark,
				nonce_str: '',//预留
    		}
			
			console.log('--- postData ->',postData)
    	
    		this.axios.get(this.GLOBAL.baseURI + 'addQuota', {
    				params: postData
    			})
    			.then(function(response) {
    				console.log(response);
    				return response.data;
    			})
    			.catch(function(error) {
    				console.log(error);
    			})
    			.then(function(data) {
    				// always executed
    				console.log(data.data);
    	
    				self.disabled = false;
    	
    				if (data.code == 200) {
						
						// if(data.data.result_code == 'success'){
							
						// 	self.$toasted.success('保存成功', {
						// 		position: 'top-center',
						// 	}).goAway(2000)
							
						// 	self.initData();
							    	
						// 	$('#modal').modal('hide');
						// }
						// else{
						// 	self.$toasted.error(data.data.result_msg, {
						// 		position: 'top-center',
						// 	}).goAway(2000)
						// }
						
						self.$toasted.success('充值成功', {
							position: 'top-center',
						}).goAway(2000)
						
						self.initData();
						    	
						$('#modal').modal('hide');
    					
    				} 
					else if (data.code == 101) {
    					//登录超时
						$('#modal').modal('hide');
						self.logoff();
    				} 
					else {
						self.$toasted.error(data.message, {
							position: 'top-center',
						}).goAway(2000)
    				}
    			});
    	},
		
		
    	onOutletChangeListener(e) {
    		let self = this;
    		console.log('--- outlet ID->', e)
    		self.outlet_id = e;
    	
    		self.initData();
    	},
    }
}
</script>

<style>
</style>
